import React from 'react';
import logo1 from '../assets/images/h_20240815153703_0450f3d9dbf0ee221c28adc6c6dd501f.webp';
import logo2 from '../assets/images/h_20240815153812_a710d5276f58b3d4c46ee560423257ce.webp';
import logo3 from '../assets/images/h_20240815153838_2c28641273011c648345b54f216a8312.webp';
import logo4 from '../assets/images/h_20240815153917_2db111d6917c48032243e69f6943fb7d.webp';
import logo5 from '../assets/images/h_20240815153954_c2c9e6fce14c062e55f8dbaf13eab29e.webp';
import logo6 from '../assets/images/h_20240815154013_2b542b19a8c780d5652146d605a28c3b.webp';
import logo7 from '../assets/images/ikhbodisales.png';
import logo8 from '../assets/images/ikhbodifoods.png';
import logo9 from '../assets/images/ikhbodisecurity.png';
import logo10 from '../assets/images/Undurkhanart.png';
import logo11 from '../assets/images/undurtsagaantsahiur.png';

const ClientSection = () => {
  const clientsLeft = [
    { logo: logo1, name: "Их бодь групп ХХК", link: "https://ikhbodi.mn/" },
    { logo: logo2, name: "Их бодь констракшн ХХК", link: "https://www.facebook.com/Ikhbodiconstruction" },
    { logo: logo3, name: "Их бодь экүйфмент ХХК", link: "https://www.facebook.com/profile.php?id=61558073147368" },
    { logo: logo4, name: "Их бодь инженеринг ХХК", link: "https://www.facebook.com/profile.php?id=61566406613087" },
    { logo: logo5, name: "Их бодь зуурмаг ХХК", link: "https://www.facebook.com/profile.php?id=61558102059576" },
  ];

  const clientsRight = [
    { logo: logo6, name: "Их бодь хангамж ХХК", link: "https://www.facebook.com/profile.php?id=61561541376468" },
    { logo: logo7, name: "Их бодь борлуулалт ХХК", link: "https://www.facebook.com/ikhbodisales" },
    { logo: logo8, name: "Их бодь фүүдс ХХК", link: "https://www.facebook.com/profile.php?id=61565513258863" },
    { logo: logo9, name: "Их бодь секьюрити ХХК", link: "https://www.facebook.com/profile.php?id=61565223617709" },
    { logo: logo10, name: "Өндөрхаан арт ХХК", link: "https://www.facebook.com/profile.php?id=61561386796540" },
    { logo: logo11, name: "Өндөр цагаан цахиурт ХХК", link: "https://www.facebook.com/profile.php?id=61565966302237" },
  ];

  return (
    <div className="bg-gray-100 py-10 lg:py-20">
      <div className="container max-w-6xl mx-auto p-4 flex flex-col lg:flex-row items-center gap-8">
        {/* Text Content */}
        <div className="w-full lg:w-2/5 text-center lg:text-left flex flex-col gap-5">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-blue-900 mb-4">
            Харилцагч <br className="hidden lg:block" /> байгууллагууд
          </h1>
        </div>

        {/* Logos Scrolling Container */}
        <div className="w-full lg:w-3/5 overflow-hidden">
          <div className="flex flex-col gap-6 py-5">
            {/* First Row of Scrolling Logos */}
            <div className="flex gap-4 md:gap-5 animate-scroll-left">
              {clientsLeft.map((client, index) => (
                <a
                  key={`logo-top-${index}`}
                  href={client.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-36 w-[115px] md:w-44 lg:w-52 py-2 max-h-[60px] md:max-h-[70px] flex items-center justify-center rounded-[10px] shadow-lg bg-white"
                >
                  <img
                    src={client.logo}
                    alt={`${client.name}`}
                    className="h-auto max-h-[40px] md:max-h-[50px] w-auto object-contain"
                  />
                </a>
              ))}
            </div>

            {/* Second Row of Scrolling Logos (Reverse) */}
            <div className="flex gap-4 md:gap-5 animate-scroll-right">
              {clientsRight.map((client, index) => (
                <a
                  key={`logo-bottom-${index}`}
                  href={client.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-36 w-[115px] md:w-44 lg:w-52 py-2 max-h-[60px] md:max-h-[70px] flex items-center justify-center rounded-[10px] shadow-lg bg-white"
                >
                  <img
                    src={client.logo}
                    alt={`${client.name}`}
                    className="h-auto max-h-[40px] md:max-h-[50px] w-auto object-contain"
                  />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientSection;
