// CircleProcess.js
import React from 'react';

const CircleProcess = () => {
    const steps = [
        { number: 1, text: "Сургалт хөгжил" },
        { number: 2, text: "Талент менежмент" },
        { number: 3, text: "Хүний нөөцийн бодлого, төлөвлөлт" },
        { number: 4, text: "Хүний нөөцийн бүрдүүлэлт, сонгон шалгаруулалт" },
        { number: 5, text: "Гүйцэтгэлийн үнэлгээ" },
        { number: 6, text: "Хөдөлмөрийн харилцаа" },
    ];

    return (
        <div className="circle-process relative w-full h-[500px] flex items-center justify-center mb-5">
            <div className="center-text absolute bg-gray-200 text-center w-20 h-20 rounded-full flex items-center justify-center text-lg font-bold">
                HRMO
            </div>
            {steps.map((step, index) => (
                <div
                    key={step.number}
                    className={`step absolute transform -translate-x-1/2 -translate-y-1/2`}
                    style={{
                        top: `${50 + 40 * Math.sin((index / 6) * 2 * Math.PI)}%`,
                        left: `${50 + 40 * Math.cos((index / 6) * 2 * Math.PI)}%`,
                    }}
                >
                    <div className="w-32 h-32 rounded-full bg-blue-200 flex items-center justify-center text-center">
                        <div className="text-xs">
                            {/* <strong>{step.number}</strong><br /> */}
                            {step.text}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CircleProcess;
