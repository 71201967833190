import React from 'react';
import avatar from '../assets/images/profile.png';
import major from '../assets/images/major-minor.webp';
import bsedu from '../assets/images/bs-edu.png';
import arrowmini from '../assets/images/arrow-mini.svg';


const MajorCard = ({ imageUrl, category, categoryColor, title, author, authorImage, date, link }) => {
    return (
        <div
            className="box max-w-sm mx-auto bg-white rounded-lg shadow-md overflow-hidden transition-transform transform hover:scale-105"
            data-aos="fade-up"
        >
            <div className="post_grid_content_wrapper">
                {/* Image Section with Overlay */}
                <div className="relative">
                    <a href={link} className="block" title={title}>
                        <img
                            src={imageUrl}
                            alt={title}
                            className="w-full h-64 object-cover"
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-30"></div>
                    </a>
                    {/* Category Badge */}
                    <span
                        className="absolute top-2 left-2 px-3 py-1 text-sm font-semibold text-white rounded-md"
                        style={{ backgroundColor: categoryColor }}
                    >
                        <a href="#" className="text-white">{category}</a>
                    </span>
                </div>

                {/* Content Section */}
                <div className="p-4">
                    <h3 className="text-lg font-semibold mb-2">
                        <a href={link} className="text-blue-800 hover:text-blue-600">
                            {title}
                        </a>
                    </h3>
                    <div className="flex items-center text-gray-600 text-sm">
                        {/* Author */}
                        <span className="flex items-center mr-4">
                            <img
                                src={authorImage}
                                alt={author}
                                className="w-6 h-6 rounded-full mr-2"
                            />
                            <a href="#" className="hover:text-blue-500">{author}</a>
                        </span>

                        {/* Date */}
                        <span className="flex items-center ml-auto hover:cursor-pointer hover:text-blue-700">
                            Дэлгэрэнгүй
                            <img className="pl-1" src={arrowmini} alt="arrow icon" />
                        </span>
                    </div>
                </div>

            </div>
        </div>
    );
};

// Example of usage
const MajorList = () => {

    const majors = [
        {
            imageUrl: major,
            category: 'Мэргэжил cургалт',
            categoryColor: '#0056e1',
            title: 'Мужаан',
            author: 'Сургагч багш',
            authorImage: avatar,
            date: '2024-11-13',
            link: '#'
        },
        {
            imageUrl: bsedu,
            category: 'Мэргэжил cургалт',
            categoryColor: '#0056e1',
            title: 'Арматурчин',
            author: 'Сургагч багш',
            authorImage: avatar,
            date: '2024-11-13',
            link: '#'
        },
        {
            imageUrl: major,
            category: 'Мэргэжил cургалт',
            categoryColor: '#0056e1',
            title: 'Сантехникч',
            author: 'Сургагч багш',
            authorImage: avatar,
            date: '2024-11-13',
            link: '#'
        },
        {
            imageUrl: bsedu,
            category: 'Мэргэжил cургалт',
            categoryColor: '#0056e1',
            title: 'Цахилгаанчин',
            author: 'Сургагч багш',
            authorImage: avatar,
            date: '2024-11-13',
            link: '#'
        },
        {
            imageUrl: major,
            category: 'Мэргэжил cургалт',
            categoryColor: '#0056e1',
            title: 'Гагнуурчин',
            author: 'Сургагч багш',
            authorImage: avatar,
            date: '2024-11-13',
            link: '#'
        },
        {
            imageUrl: bsedu,
            category: 'Мэргэжил cургалт',
            categoryColor: '#0056e1',
            title: 'Засалчин',
            author: 'Сургагч багш',
            authorImage: avatar,
            date: '2024-11-13',
            link: '#'
        },
        {
            imageUrl: major,
            category: 'Мэргэжил cургалт',
            categoryColor: '#0056e1',
            title: 'Плитачин',
            author: 'Сургагч багш',
            authorImage: avatar,
            date: '2024-11-13',
            link: '#'
        },
        {
            imageUrl: bsedu,
            category: 'Мэргэжил cургалт',
            categoryColor: '#0056e1',
            title: 'Өрөг угсрагч',
            author: 'Сургагч багш',
            authorImage: avatar,
            date: '2024-11-13',
            link: '#'
        }
    ];

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
            {majors.map((major, index) => (
                <MajorCard key={index} {...major} />
            ))}
        </div>
    );
};

export default MajorList;
