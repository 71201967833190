import React, { useState, useEffect } from 'react';
import logoWhite from '../assets/images/logo-white-with-fullname.png';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink, useLocation } from 'react-router-dom';

const Header = () => {
    const location = useLocation();
    const isHomePage = location.pathname === '/';
    const [scrolling, setScrolling] = useState(false);
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

    useEffect(() => {
        // if (isHomePage) {
            const handleScroll = () => {
                setScrolling(window.scrollY > 50);
            };
            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        // } else {
        //     setScrolling(false);
        // }
    }, [isHomePage]);

    const navLinks = [
        { to: "hero", label: "Нүүр" },
        { to: "about", label: "Бидний тухай" },
        { to: "services", label: "Үйлчилгээ" },
        { to: "/career", label: "Ажлын байр", isExternal: true },
        { to: "contact", label: "Холбоо барих" },
    ];

    const handleNavLinkClick = () => {
        setIsMobileNavOpen(false);
    };

    return (
        <header className={`w-full z-50 transition-all duration-500 ease-in-out fixed top-0 left-0 ${scrolling ? 'bg-[#292f3a]' : 'bg-[rgba(35,35,35,0.5)]'}`}>
            <div className="max-w-6xl mx-auto flex justify-between items-center p-4">
                <RouterLink to="/" className="cursor-pointer">
                    <img src={logoWhite} alt="IBBS" className="h-[4rem]" />
                </RouterLink>

                <nav className="hidden md:flex space-x-6">
                    {navLinks.map((link) =>
                        isHomePage && !link.isExternal ? (
                            <ScrollLink
                                key={link.to}
                                to={link.to}
                                smooth={true}
                                offset={-80}
                                duration={500}
                                className="text-white font-semibold cursor-pointer relative  font-sans after:content-[''] after:absolute after:left-0 after:bottom-0 after:w-0 after:h-0.5 after:bg-white after:transition-all after:duration-300 hover:after:w-full"
                            >
                                {link.label}
                            </ScrollLink>
                        ) : (
                            <RouterLink
                                key={link.to}
                                to={link.isExternal ? link.to : "/"}
                                className="text-white font-semibold cursor-pointer relative  font-sans after:content-[''] after:absolute after:left-0 after:bottom-0 after:w-0 after:h-0.5 after:bg-white after:transition-all after:duration-300 hover:after:w-full"
                            >
                                {link.label}
                            </RouterLink>
                        )
                    )}
                </nav>

                <div className="md:hidden">
                    <button
                        onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
                        className="text-white focus:outline-none"
                    >
                        {isMobileNavOpen ? <FaTimes /> : <FaBars />}
                    </button>
                </div>
            </div>

            {isMobileNavOpen && (
                <div className={`md:hidden ${scrolling ? 'bg-[#292f3a]' : 'bg-[rgba(35,35,35,0.5)]'} p-4 space-y-4`}>
                    {navLinks.map((link) =>
                        isHomePage && !link.isExternal ? (
                            <ScrollLink
                                key={link.to}
                                to={link.to}
                                smooth={true}
                                offset={-80}
                                duration={500}
                                className="block text-white font-semibold cursor-pointer relative  font-sans after:content-[''] after:absolute after:left-0 after:bottom-0 after:w-0 after:h-0.5 after:bg-white after:transition-all after:duration-300 hover:after:w-full"
                                onClick={handleNavLinkClick}
                            >
                                {link.label}
                            </ScrollLink>
                        ) : (
                            <RouterLink
                                key={link.to}
                                to={link.isExternal ? link.to : "/"}
                                className="block text-white font-semibold cursor-pointer relative  font-sans after:content-[''] after:absolute after:left-0 after:bottom-0 after:w-0 after:h-0.5 after:bg-white after:transition-all after:duration-300 hover:after:w-full"
                                onClick={handleNavLinkClick}
                            >
                                {link.label}
                            </RouterLink>
                        )
                    )}
                </div>
            )}
        </header>
    );
};

export default Header;
