import React, { useState, useEffect } from 'react';
import CareerFilter from './CareerFilter';
import CareerCard from './CareerCard';
import { Helmet } from "react-helmet";
import career from '../assets/images/4.jpeg';

function Career() {
    const [careerData, setCareerData] = useState([]);
    const [filteredCareers, setFilteredCareers] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch('/careerList.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setCareerData(data);
                setFilteredCareers(data);
            })
            .catch(error => {
                setError(error);
                console.error('Error fetching data:', error);
            });
    }, []);

    const handleFilterChange = (filters) => {
        const filtered = careerData.filter(career => {
            return (
                (!filters.company.length || filters.company.includes(career.company)) &&
                (!filters.industryType.length || filters.industryType.includes(career.industryType))
            );
        });
        setFilteredCareers(filtered);
    };

    return (
        <div>
            <Helmet>
                <title>IBBS | АЖЛЫН БАЙР</title>
                <meta name="description" content="Description of the page" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="IBBS | АЖЛЫН БАЙР" />
                <meta property="og:description" content="Description for sharing" />
                <meta property="og:image" content="https://example.com/image.jpg" />
            </Helmet>
            
            {/* Hero Image Section */}
            <section className="h-64 sm:h-80 md:h-96 bg-cover bg-center" style={{ backgroundImage: `url(${career})` }}>
            </section>

            <div className="container mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <div className="max-w-6xl mx-auto flex flex-col lg:flex-row gap-4">
                    {/* Sidebar filter */}
                    <div className="w-full lg:w-1/3">
                        <CareerFilter careerData={careerData} onFilterChange={handleFilterChange} />
                    </div>
                    
                    {/* Career listings */}
                    <div className="w-full lg:w-2/3 space-y-4">
                        {filteredCareers.map(career => (
                            <CareerCard key={career.id} career={career} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Career;
