// AboutUs.js
import React from 'react';
import buildingImage from '../assets/images/building.jpg';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';

const socials = [
  { icon: <FaFacebook />, link: 'https://www.facebook.com/profile.php?id=61559300893405&mibextid=LQQJ4d', title: "Facebook", description: "Gain insights into your data." },
  { icon: <FaInstagram />, link: '', title: "Instagram", description: "Enhance team productivity." },
  { icon: <FaTwitter />, link: '', title: "Twitter", description: "Personalize your experience." },
];

const AboutUs = () => {
  return (
    <>
      <div id="about" className="max-w-6xl mx-auto px-4">
        <div className="container mx-auto">
          <section className="flex flex-col md:flex-row justify-between items-center md:h-screen space-y-8 md:space-y-0">

            {/* Left Content Section */}
            <div className="w-full md:w-1/2 md:pr-8 text-center md:text-left">
              <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-blue-900 mb-4">Бидний тухай</h1>
              <p className="text-gray-700 text-justify mb-6">
                “Их бодь бэст сольюшнс” ХХК нь 2024 онд үүсгэн байгуулагдсан. Бид харилцагч байгууллагууддаа мэргэжлийн чанартай үйлчилгээ үзүүлэх, ажлын таатай орчинд мэргэжлээрээ өсөн дэвших нөхцлөөр хангагдсан өндөр бүтээмжтэй ажилтнуудыг бий болгох зорилготойгоор  үйл ажиллагаагаа явуулж байна.
              </p>

              {/* Social Icons */}
              <div className="flex justify-center md:justify-start space-x-4 mt-6">
                {socials.map((social, index) => (
                  <a
                    key={index}
                    href={social.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-blue-600 text-white rounded-full w-12 h-12 flex items-center justify-center hover:bg-blue-800 transition"
                    aria-label={social.title}
                  >
                    {social.icon}
                  </a>
                ))}
              </div>
            </div>

            {/* Right Image Section with Triangle Cut */}
            <div className="w-full md:w-1/2 relative h-64 md:h-full">
              <div
                className="hidden md:block absolute inset-0 clip-triangle"
                style={{ backgroundImage: `url(${buildingImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
              ></div>
              {/* Display the image in a more mobile-friendly way */}
              <img
                src={buildingImage}
                alt="Building"
                className="md:hidden w-full h-full object-cover rounded-lg"
              />
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
