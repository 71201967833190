import React, { useState } from 'react';

const CareerCard = ({ career }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div
            onClick={toggleAccordion}
            className="p-4 bg-white shadow-lg rounded-lg cursor-pointer transition transform hover:scale-105"
        >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex flex-col">
                    <div className="font-semibold text-lg">{career.position}</div>
                    <div className="text-gray-500">{career.company} | {career.industryType}</div>
                </div>
                <div className="flex flex-col md:items-end">
                    <div className="text-gray-600">Төрөл: <span className="font-semibold">{career.type}</span></div>
                    <div className="text-gray-600">Ажлын цаг: <span className="font-semibold">Бүтэн цагийн</span></div>
                    {/* <div className="text-right mt-1">{career.deadline}</div> */}
                </div>
            </div>

            {isOpen && (
                <div className="mt-4 text-gray-700">
                    <h4 className="text-md font-semibold mb-2">Гүйцэтгэх үүрэг</h4>
                    <ul className="list-disc list-inside text-sm mb-4">
                        {career.description.map((desc, index) => (
                            <li key={index}>{desc}</li>
                        ))}
                    </ul>

                    <h4 className="text-md font-semibold mb-2">Шалгуур үзүүлэлт</h4>
                    <ul className="list-disc list-inside text-sm mb-4">
                        {career.requirements.map((req, index) => (
                            <li key={index}>{req}</li>
                        ))}
                    </ul>
                    <div className='w-full items-end text-right'>
                        <a
                            href={career.link}
                            target="_blank"
                            className="border border-blue-500 text-blue-500 py-2 px-6 rounded-md font-semibold hover:border-blue-700 hover:text-blue-700 transition"
                        >
                            Анкет илгээх
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CareerCard;
