import React from 'react';
import logo from '../assets/images/logo_with_name.png';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
const Footer = () => {
    return (
        <footer className="w-full bg-[#292F3A] flex justify-center relative">
            <div className="container max-w-6xl flex flex-col items-center p-4 pt-12 pb-6">

                {/* Logo and Social Icons */}
                <div className="flex justify-between items-center w-full pb-10 text-left">
                    <div className="text-white text-xl font-bold">
                        <img src={logo} alt="IBBS" className="h-10" />
                    </div>
                    <div className="flex gap-2 mt-4 md:mt-0">
                        {/* here social icons */}
                        <a href='#' target="_blank" className="bg-gray-600 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-gray-800 transition"><FaFacebook /></a>
                        <a href='#' target="_blank" className="bg-gray-600 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-gray-800 transition"><FaInstagram /></a>
                        <a href='#' target="_blank" className="bg-gray-600 text-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-gray-800 transition"><FaTwitter /></a>

                    </div>
                </div>

                {/* Divider */}
                <div className="border border-[#434954] w-full mb-10"></div>

                {/* Footer Links, Contact Info, and Address */}
                <div className="flex flex-col gap-10 w-full pt-10">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 text-left">

                        {/* Links Section */}
                        <div className="flex flex-col gap-2">
                            <span className="text-white text-base hover:text-blue-400 cursor-pointer">Компани</span>
                            <span className="text-white text-base hover:text-blue-400 cursor-pointer">Бидний тухай</span>
                            <span className="text-white text-base hover:text-blue-400 cursor-pointer">Үйлчилгээ</span>
                        </div>

                        {/* Contact Information */}
                        <div className="flex flex-col gap-5">
                            <div className="text-white text-xl font-normal">Холбоо барих</div>
                            <div className="text-white text-base">
                                <p>Утас: 9110-4110, 8505-8860</p>
                                <p>И-мэйл: info@ibbs.mn</p>
                            </div>
                        </div>

                        {/* Address */}
                        <div className="flex flex-col gap-5">
                            <div className="text-white text-xl font-normal">Хаяг:</div>
                            <div className="text-white text-base">
                                БЗД 14-р хороо 14-р хороолол Баясах хульж оффис 2 давхарт 201 тоот.
                            </div>
                        </div>
                    </div>

                    {/* Footer Bottom Section */}
                    <div className="flex flex-col md:flex-row justify-between items-center text-center md:text-left w-full">
                        <div className="text-white text-base font-normal">
                            &copy; All rights reserved – IBBS LLC
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
