import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import service1 from '../assets/images/service-1.png';
import service2 from '../assets/images/service-2.jpg';
import service3 from '../assets/images/servcie-3.webp';
import CircleProcess from './CircleProcess';
import MajorList from './MajorList';
import emailjs from 'emailjs-com';

// Sample data for services
const services = [
    {
        id: 1,
        heroImage: service1,
        title: 'Хүний нөөцийн менежментийн аутсорсинг',
        description: 'Хүний нөөцийн аутсорсинг үйлчилгээ гэдэг нь хүний нөөцийн алба, нэгж, ажилтангүй, хүний нөөцийн үйл ажиллагаа тогтворжоогүй, гүйцэтгэх удирдлага нь хүний нөөцийн менежерийн ажил үүргийг хавсран гүйцэтгэсээр ирсэн байгууллагад болон хүний нөөцийн үйл ажиллагааг мэргэжлийн түвшинд, мэргэжлийн мэргэшсэн багаар гүйцэтгүүлэх хүсэлтэй байгууллагад зориулсан үйлчилгээ юм.',
        description2: ' Бид ийм хүсэл сонирхол бүхий байгууллагад зориулан аутсорсинг байдлаар хүний нөөцийн үйл ажиллагааг нь бүрэн болон хэсэгчилсэн байдлаар хариуцан ажилладаг.',
    },
    {
        id: 2,
        heroImage: service2,
        title: 'Мэргэжил сургалтын төв',
        description: 'Манай “Их бодь бэст сольюшнс” ХХК-ийн дэргэдэх “BS edu center” сургалтын төв нь Хөдөлмөр нийгмийн хамгааллын яамны дэргэдэх сургалт үнэлгээний институтаар баталгаажуулсан сургалтын хөтөлбөрөөр барилгын болон бусад салбарын 20-иод мэргэжлээр олон улсад хүлээн зөвшөөрөгдөх чадамжийн гэрчилгээ бүхий мэргэжлийн сургалтыг явуулж байна.',
        description2: '',
    },
    {
        id: 3,
        heroImage: service3,
        title: 'Хөдөлмөр зуучлал',
        description: 'Тун удахгүй...',
        description2: '',
    },
];

const ServiceDetail = () => {
    const { id } = useParams();
    const service = services.find((s) => s.id === parseInt(id));
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const [displayMessage, setDisplayMessage] = useState("");

    if (!service) {
        return <p>Service not found.</p>;
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission here, such as sending the data to an API or backend

        emailjs.send('your_user_id',
            'your_template_id', formData, 'your_public_key')
            .then((result) => {
                console.log(result.text);
                setDisplayMessage("Бидэнтэй холбогдсон танд баярлалаа. Бид удахгүй холбогдох болно.");
                // Display a success message to the user
            })
            .catch((error) => {
                console.error(error.text);
                setDisplayMessage("Бидэнтэй холбогдсон танд баярлалаа. Бид удахгүй холбогдох болно.");
                // Display an error message to the user
            });
        console.log(formData);
    };

    return (
        <div>
            <Helmet>
                <title>IBBS | {service.title}</title>
                <meta name="description" content={`${service.description}`} />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content={`IBBS | ${service.title}`} />
                <meta property="og:description" content={`${service.description}`} />
                <meta property="og:image" content={`${service.heroImage}`} />
            </Helmet>

            {/* Hero Image Section */}
            <section
                className="h-64 sm:h-80 md:h-96 lg:h-[28rem] bg-cover bg-center relative"
                style={{ backgroundImage: `url(${service.heroImage})` }}
            >
            </section>

            {/* Service Title & Description */}
            <div className="max-w-6xl mx-auto py-6 px-4 sm:px-6 lg:py-10 lg:px-8">
                <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold mb-4">{service.title}</h2>
                <p className="text-gray-700 text-base text-justify sm:text-lg md:text-xl">{service.description}</p>
                {service.description2 && <p className="text-gray-700 text-base text-justify sm:text-lg md:text-xl mt-2">{service.description2}</p>}
            </div>

            {/* Circle Process Diagram for Service 1 */}
            {id === '1' && (
                <>
                    <div className="max-w-6xl mx-auto px-4 px-4 sm:px-6 lg:px-8 py-6">
                        <CircleProcess />
                    </div>
                    <div className="max-w-6xl mx-auto px-4 bg-white p-8">
                        <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-blue-900 mb-4">Үнийн санал авах</h1>
                        <div className={`bg-green-100 border border-green-400 text-green-700 mb-3 px-4 py-3 rounded relative ${displayMessage ? 'block' : 'hidden'}`} role="alert">
                            <span className="block sm:inline">{displayMessage}</span>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label htmlFor="name" className="block text-gray-700">Нэр</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    className="w-full p-3 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-gray-700">И-мэйл</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    className="w-full p-3 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="message" className="block text-gray-700">Энд зурвасаа бичнэ үү</label>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleInputChange}
                                    className="w-full p-3 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    rows="5"
                                    required
                                ></textarea>
                            </div>
                            <button type="submit" className="w-full border border-blue-600 text-blue-600 py-2 px-6 rounded-md font-semibold hover:bg-blue-600 hover:text-white transition">
                                Илгээх
                            </button>
                        </form>
                    </div>
                </>

            )}
            {id === '2' && (
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
                    <MajorList />
                </div>
            )}
        </div>
    );
};

export default ServiceDetail;
