import React, { useState } from 'react';

function CareerFilter({ careerData, onFilterChange }) {
    const [selectedFilters, setSelectedFilters] = useState({
        company: [],
        industryType: [],
    });

    const companyList = [
        "Их бодь групп ХХК", "Их бодь констракшн ХХК", "Өндөрхаан арт ХХК", "Их бодь инженеринг ХХК", "Өндөр цагаан цахиурт ХХК", "Их бодь борлуулалт ХХК",
        "Их бодь экүйфмент ХХК", "Их бодь секьюрити ХХК", "Их бодь фүүдс ХХК", "Их бодь зуурмаг ХХК"
    ];

    const industryTypeList = [
        'Тээвэр', 'Үйлдвэрлэл', 'Барилга угсралт', 'Хоол үйлдвэрлэл',
        'Зураг төсөл', 'Захиргаа хүний нөөц', 'Санхүү', 'Харуул хамгаалалт'
    ];

    const industryCounts = industryTypeList.reduce((acc, industry) => {
        acc[industry] = careerData.filter(career => career.industryType === industry).length || 0;
        return acc;
    }, {});

    const handleFilterChange = (filterType, filterValue) => {
        const updatedFilters = {
            ...selectedFilters,
            [filterType]: selectedFilters[filterType].includes(filterValue)
                ? selectedFilters[filterType].filter((value) => value !== filterValue)
                : [...selectedFilters[filterType], filterValue],
        };

        setSelectedFilters(updatedFilters);
        onFilterChange(updatedFilters);
    };

    // Count jobs for each company
    const companyCounts = companyList.reduce((acc, company) => {
        acc[company] = careerData.filter((career) => career.company === company).length;
        return acc;
    }, {});

    return (
        <div className="p-4 bg-gray-100 rounded-md">
            <div className="mb-4">
                <h3 className="text-lg font-bold mb-2">Үйл ажиллагааны чиглэл</h3>
                <div className="space-y-2">
                    {industryTypeList.map((industryType) => (
                        <label key={industryType} className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    value={industryType}
                                    checked={selectedFilters.industryType.includes(industryType)}
                                    onChange={(e) => handleFilterChange('industryType', e.target.value)}
                                    className="mr-2 border-gray-300 rounded-sm"
                                />
                                <span>{industryType} </span>
                            </div>
                            <span className="text-gray-500">{industryCounts[industryType] || 0}</span>
                        </label>
                    ))}
                </div>
            </div>

            <div>
                <h3 className="text-lg font-bold mb-2">Компани</h3>
                <div className="space-y-2">
                    {companyList.map((company) => (
                        <label key={company} className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    value={company}
                                    checked={selectedFilters.company.includes(company)}
                                    onChange={(e) => handleFilterChange('company', e.target.value)}
                                    className="mr-2 border-gray-300 rounded-sm"
                                />
                                <span>{company}</span>
                            </div>
                            <span className="text-gray-500">{companyCounts[company] || 0}</span>
                        </label>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default CareerFilter;
