import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import outsourcing from '../assets/images/outsourcing-service.png';
import training from '../assets/images/training.png';
import job from '../assets/images/job-position.png';

const ServiceCard = ({ id, icon, title, description }) => {
    return (
        <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-lg max-w-xs">
            <img
                src={icon}
                alt={title}
                className="w-16 h-16"
            />
            <h5 className="text-lg mb-2">{title}</h5>
            <p className="text-gray-500 text-sm mb-4 text-justify">{description}</p>
            {/* Use Link from react-router-dom for routing */}
            <Link
                to={`/services/${id}`}
                className="mt-auto border border-blue-600 text-blue-600 py-2 px-6 rounded-md font-semibold hover:bg-blue-600 hover:text-white transition"
            >
                Дэлгэрэнгүй
            </Link>
        </div>
    );
};

const ServicesList = () => {
    const services = [
        {
            id: 1,
            icon: outsourcing,
            title: 'Хүний нөөцийн менежментийн аутсорсинг',
            description: 'Хүний нөөцийн менежментийн үйл ажиллагаагаа мэргэжлийн байгууллагаар гүйцэтгүүлж өөрийн үндсэн бизнест анхаарах боломж.',
        },
        {
            id: 2,
            icon: training,
            title: 'Мэргэжил сургалтын төв',
            description: 'Олон улсад хүлээн зөвшөөрөгдөхүйц чадамжийн гэрчилгээ олгох мэргэжил сургалтын төв.',
        },
        {
            id: 3,
            icon: job,
            title: 'Хөдөлмөр зуучлал',
            description: 'Үндсэн болон цагийн ажилтан зуучлах үйлчилгээ.',
        },
    ];

    return (
        <div id="services" className="py-16 bg-gray-100">
            <div className="max-w-6xl mx-auto px-6 text-center">
                <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-blue-900 mb-4">Үйлчилгээ</h1>
                <div className="flex flex-wrap gap-4 justify-center">
                    {services.map((service, index) => (
                        <ServiceCard
                            key={index}
                            id={service.id}
                            icon={service.icon}
                            title={service.title}
                            description={service.description}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ServicesList;
