import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import ClientsSection from './components/ClientsSection';
import AboutUs from './components/AboutUs';
import ServicesList from './components/ServicesList';
import HrOutsourcingSection from './components/HrOutsourcingSection';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';
import ServiceDetail from './components/ServiceDetail';
import Career from './components/Career';

function App() {
  const [activeSection, setActiveSection] = useState(0);
  const sectionRefs = useRef([]);

  useEffect(() => {
    const titles = [
      'IBBS | IKH BODI BEST SOLUTIONS',
      'IBBS | БИДНИЙ ТУХАЙ',
      'IBBS | ҮЙЛЧИЛГЭЭ',
      'IBBS | ХАМТРАГЧ',
      'IBBS | ХОЛБОО БАРИХ'
    ];

    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;

      sectionRefs.current.forEach((sectionRef, index) => {
        if (sectionRef) {
          const sectionTop = sectionRef.offsetTop;
          const sectionBottom = sectionTop + sectionRef.offsetHeight;

          if (scrollY >= sectionTop - windowHeight / 2 && scrollY < sectionBottom - windowHeight / 2) {
            if (activeSection !== index) {
              setActiveSection(index);
              document.title = titles[index];
            }
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [activeSection]);

  return (
    <Router>
      <Header />
      <main>
        <Routes>
          {/* Define routes */}
          <Route path="/" element={
            <>
              <section id="hero" ref={(el) => (sectionRefs.current[0] = el)}>
                <HeroSection />
              </section>
              <section id="about" ref={(el) => (sectionRefs.current[1] = el)} className="bg-gradient-to-b from-white via-[#faf3eb] to-white">
                <AboutUs />
              </section>
              <section ref={(el) => (sectionRefs.current[2] = el)} id="services">
                <ServicesList />
              </section>
              <HrOutsourcingSection />
              <section ref={(el) => (sectionRefs.current[3] = el)} id="clients">
                <ClientsSection />
              </section>
              <section ref={(el) => (sectionRefs.current[4] = el)} id="contact">
                <ContactSection />
              </section>
            </>
          } />
          {/* Add a new route for ServiceDetail */}
          <Route path="/career" element={<Career />} />
          <Route path="/services/:id" element={<ServiceDetail />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
