import React from "react";
import { FaCheck } from 'react-icons/fa';
import outsourcing from '../assets/images/outsource-2.png';
import joinus from '../assets/images/outsource-3.png';

const HrOutsourcingSection = () => {
    return (
        <>
            <div className="flex pb-10 bg-gradient-to-b from-white via-[#EBF8FA] to-white">
                <div className="container mx-auto max-w-6xl py-16 flex flex-col md:flex-row justify-between gap-5">
                    {/* Image Container */}
                    <div className="relative w-full md:w-1/2 md:py-3 sm:py-1 xs:py-0 mb-6 md:mb-0">
                        <img
                            src={outsourcing}
                            alt="feature"
                            className="w-full h-full object-contain"
                        />
                    </div>

                    {/* Content Container */}
                    <div className="flex flex-col justify-center w-full md:w-1/2 lg:py-0 gap-5 px-4 md:px-0">
                        <h2 className="text-lg xs:text-[20px] md:text-[32px] font-bold text-[#3C3C3C]">
                            Хүний нөөцийн менежментийн{" "}
                            <span className="bg-gradient-to-b from-[#44acff] to-[#0056e1] text-transparent bg-clip-text">
                                аутсорсинг{" "}
                            </span>
                            гэж юу вэ?
                        </h2>

                        <p className="text-sm md:text-base text-[#3C3C3C] text-justify">
                            Байгууллагын хүний нөөцийн менежментийн үйл ажиллагааг гэрээлэн гүйцэтгэх үйл явцыг хүний нөөцийн менежментийн аутсорсингийн үйлчилгээ гэнэ.
                        </p>
                        <p className="text-sm md:text-base text-[#3C3C3C] text-justify">
                            Хүний нөөцийн менежментийн аутсорсингийн үйлчилгээ авснаар дараах давуу талуудтай:
                        </p>

                        {/* Custom List */}
                        <ul className="list-none space-y-4 text-sm md:text-base pl-[25px] text-[#3C3C3C]">
                            {["Зардал хэмнэх", "Үндсэн үйл ажиллагаандаа анхаарч ашгаа нэмэх", "Хүний нөөцийн асуудлаа мэргэжлийн багт даатгах"].map((item, index) => (
                                <li key={index} className="relative pl-8 leading-none">
                                    <span className="absolute left-0 top-0 h-5 w-5 rounded-full bg-gradient-to-r from-[#44acff] to-[#0056e1] flex items-center justify-center text-white text-xs font-bold"><FaCheck /></span>
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="flex pb-10 bg-white">
                <div className="container mx-auto max-w-6xl py-16 flex flex-col md:flex-row justify-between gap-5">

                    {/* Content Container */}
                    <div className="relative w-full md:w-1/2 md:py-3 sm:py-1 xs:py-0 mb-6 md:mb-0 px-4 md:px-0">
                        <h2 className="text-lg xs:text-[20px] md:text-[32px] mb-8 font-bold text-[#3C3C3C]">
                            <span className="bg-gradient-to-b from-[#44acff] to-[#0056e1] text-transparent bg-clip-text">
                                Биднийг сонгох шалтгаан{" "}
                            </span>
                        </h2>

                        {/* Custom List */}
                        <ul className="list-none space-y-4 text-sm md:text-base pl-[25px] text-[#3C3C3C]">
                            {["Хүний нөөцийн менежментийн магистр, эрх зүйч , сэтгэл зүйч нараас бүрдсэн мэргэжлийн найдвартай хамт олон", "Хүний нөөцийн менежментийн бүх функцийг хамарсан цогц үйлчилгээ", "Харилцахад ойлгомжтой маш тодорхой үйлчилгээний процесс", "Зөвхөн танай байгууллагын онцлог, нөхцөл байдалд тохирсон үйлчилгээ үзүүлнэ"].map((item, index) => (
                                <li key={index} className="relative pl-8 leading-none">
                                    <span className="absolute left-0 top-0 h-5 w-5 rounded-full bg-gradient-to-r from-[#44acff] to-[#0056e1] flex items-center justify-center text-white text-xs font-bold"><FaCheck /></span>
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Image Container */}
                    <div className="hidden md:flex flex-col justify-center w-full md:w-1/2 lg:py-0 gap-5">
                        <img
                            src={joinus}
                            alt="feature"
                            className="w-full h-full object-contain"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default HrOutsourcingSection;
