import React, { useState, useEffect } from 'react';
import backgroundImage3 from '../assets/images/3.jpg';
import backgroundImage4 from '../assets/images/4.jpeg';
import backgroundImage5 from '../assets/images/5.jpg';

const HeroSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderDuration = 5000; // 5 seconds for example

  const slides = [
    {
      title: "АЗ ЖАРГАЛТАЙ\nАЖИЛТАН", // Two-line title
      description: "",
      backgroundImage: `url(${backgroundImage4})`,
      alignment: "right",
      paddingClasses: "pr-10 md:pr-[160px] md:pb-[220px]", // Adjusted padding for right alignment
      positionClasses: "items-end justify-end" // Vertical and horizontal positioning
    },
    {
      title: "ӨНДӨР БҮТЭЭМЖТЭЙ\nАЖИЛТАН", // Two-line title
      description: "",
      backgroundImage: `url(${backgroundImage5})`,
      alignment: "left",
      paddingClasses: "pl-10 md:pl-[160px] md:pb-[160px]", // Adjusted padding for left alignment
      positionClasses: "items-end justify-start" // Vertical and horizontal positioning
    },
    {
      title: "УР ЧАДВАРТАЙ\nАЖИЛТАН",
      description: "",
      backgroundImage: `url(${backgroundImage3})`,
      alignment: "right",
      paddingClasses: "pl-10 md:pl-[160px] md:pb-[180px]",
      positionClasses: "items-end justify-start"
    }
  ];

  const nextSlide = () => {
    setCurrentIndex((currentIndex + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentIndex((currentIndex - 1 + slides.length) % slides.length);
  };

  useEffect(() => {
    const intervalId = setInterval(nextSlide, sliderDuration);
    return () => clearInterval(intervalId);
  }, [currentIndex, sliderDuration]);

  return (
    <div className="relative w-full h-screen overflow-hidden">
      <div
        className="flex transition-transform duration-700 ease-in-out"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {slides.map((slide, index) => (
          <div
            key={index}
            className="w-full flex-shrink-0 h-screen flex items-center text-white bg-cover bg-center"
            style={{ backgroundImage: slide.backgroundImage }}
          >
            {/* Slide content with alignment and padding adjustments */}
            <div
              className={`bg-black bg-opacity-40 w-full h-full flex pb-20 ${slide.positionClasses} ${slide.paddingClasses} `}
            >
              <div className={`text-${slide.alignment} flex flex-col gap-4`}>
                <h1 className="text-4xl font-bold whitespace-pre-wrap hero-title">
                  {slide.title}
                </h1>
                <p className="mt-4 text-xl">{slide.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Previous Button */}
      <button
        onClick={prevSlide}
        className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-800 bg-opacity-60 text-white p-3 shadow-lg hover:bg-opacity-80 transition"
        style={{ borderRadius: '10%' }}
        aria-label="Previous Slide"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
        </svg>
      </button>

      {/* Next Button */}
      <button
        onClick={nextSlide}
        className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-800 bg-opacity-60 text-white p-3 shadow-lg hover:bg-opacity-80 transition"
        style={{ borderRadius: '10%' }}
        aria-label="Next Slide"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
        </svg>
      </button>
    </div>
  );
};

export default HeroSection;
