import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const ContactSection = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });
    const [displayMessage, setDisplayMessage] = useState("");

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission here, such as sending the data to an API or backend

        emailjs.send('your_user_id',
            'your_template_id', formData, 'your_public_key')
            .then((result) => {
                console.log(result.text);
                setDisplayMessage("Бидэнтэй холбогдсон танд баярлалаа. Бид удахгүй холбогдох болно.");
                // Display a success message to the user
            })
            .catch((error) => {
                console.error(error.text);
                setDisplayMessage("Бидэнтэй холбогдсон танд баярлалаа. Бид удахгүй холбогдох болно.");
                // Display an error message to the user
            });
        console.log(formData);
    };

    return (
        <>
            <div className="w-full flex justify-center relative">
                <div className="container max-w-6xl flex flex-col">
                    <div className="flex flex-col md:flex-row">
                        {/* Contact Info Section */}
                        <div className="md:w-1/2 bg-white p-8">
                            <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-blue-900 mb-4">Холбоо барих</h1>
                            <div className={`bg-green-100 border border-green-400 text-green-700 mb-3 px-4 py-3 rounded relative ${displayMessage ? 'block' : 'hidden'}`} role="alert">
                                <span className="block sm:inline">{displayMessage}</span>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-4">
                                    <label htmlFor="name" className="block text-gray-700">Нэр</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        className="w-full p-3 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        required
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="email" className="block text-gray-700">И-мэйл</label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        className="w-full p-3 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        required
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="message" className="block text-gray-700">Энд зурвасаа бичнэ үү</label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleInputChange}
                                        className="w-full p-3 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        rows="5"
                                        required
                                    ></textarea>
                                </div>
                                <button type="submit" className="w-full border border-blue-600 text-blue-600 py-2 px-6 rounded-md font-semibold hover:bg-blue-600 hover:text-white transition">
                                    Илгээх
                                </button>
                            </form>
                        </div>

                        {/* Map Section */}
                        <div className="md:w-1/2 bg-white">
                            <div className="w-full h-full">
                                <iframe
                                    title="IBBS"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2674.025722132396!2d106.94385252036248!3d47.91653975278523!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d969223ede752a1%3A0x65a94722bbb24c2!2sBayasakh%20Khulij%20LLC!5e0!3m2!1sen!2smn!4v1730994447732!5m2!1sen!2smn"
                                    className="w-full h-full border-0"
                                    allowFullScreen=""
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactSection;
